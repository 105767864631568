<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>جميع المناقصات</h3></div>
    <div class="container">
      <v-flex>
        <v-row v-if="allTendersFun.length >= 1">
          <v-col cols="12" lg="8" md="8" sm="12" xs="12" class="mt-10">
            <v-card max-width="650" class="mx-auto">
              <v-toolbar color="indigo" dark>
                <v-toolbar-title>
                  أهم المناقصات الحاليه بالشركة لعام (2022)</v-toolbar-title
                >
              </v-toolbar>

              <v-list>
                <template v-for="(item, index) in allTendersFun">
                  <v-list-item :key="item.item">
                    <v-list-item-avatar size="70">
                      <v-img
                        src="https://staticfpu.bu.edu.eg/NewsImgs/1396872143.jpg"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="mr-3">
                      <v-list-item-title
                        ><router-link
                          class="linkTender"
                          :to="{
                            name: 'selectedTender',
                            params: { userId: item.slug },
                          }"
                        >
                          {{ item.title }}</router-link
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item.created_at">{{
                        item.created_at.substring(0, 16)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <hr
                    class="white ma-2"
                    :key="index"
                    v-if="!(index == allTendersFun.length - 1)"
                  />
                </template>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" xs="12">
            <mLink></mLink>
          </v-col>
        </v-row>

        <v-row v-else>
          <h1 class="pa-5">لا توجد مناقصات حاليه</h1>
          <!--<v-img
            :src="loadingLink"
            width="200px"
            height="200px"
            contain
            class="loadImgClass"
          ></v-img
        >--></v-row
        >
      </v-flex>
    </div>
  </div>
</template>
<script>
import mLink from "../../components/link.vue";
export default {
  created() {
    this.$store.dispatch("findAllTenders");
  },
  data() {
    return {
      loadingLink: require("@/assets/Images/load.svg"),
    };
  },
  components: { mLink },
  computed: {
    allTendersFun() {
      return this.$store.state.allTenders;
    },
  },
};
</script>
<style>
.imgClosed {
  margin-right: 200px;
}
.linkTender {
  text-decoration: none;
  color: indigo !important;
  font-weight: bold;
}
</style>
