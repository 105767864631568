<template>
  <div>
    <h2>فيديو عن <span class="green--text">الشركه </span></h2>
    <!-- <span v-html="adminInfo.ascww_video_link" /> 
    src="https://www.youtube.com/embed/no-cUL1jV5U" -->
    <iframe
      class="mr-3"
      width="300"
      height="200"
      :src="adminInfo.ascww_video_link"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <h2 class="mt-3">روابط <span class="green--text">سريعه</span></h2>
    <v-expansion-panels class="mb-6 mr-5" v-model="panels" multiple>
      <v-expansion-panel
        v-for="(item, i) in ArrayOFAnElsherka"
        :key="i"
        class="changeTitleStyle"
      >
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.title == 'رساله الشركة'"
          ><v-img :src="item.img"></v-img
        ></v-expansion-panel-content>
        <v-expansion-panel-content
          v-else-if="item.title == 'صفحتنا علي الفيسبوك'"
          ><iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F%25D8%25A7%25D9%2584%25D8%25B5%25D9%2581%25D8%25AD%25D8%25A9-%25D8%25A7%25D9%2584%25D8%25B1%25D8%25B3%25D9%2585%25D9%258A%25D8%25A9-%25D9%2584%25D8%25B4%25D8%25B1%25D9%2583%25D8%25A9-%25D9%2585%25D9%258A%25D8%25A7%25D9%2587-%25D8%25A7%25D9%2584%25D8%25B4%25D8%25B1%25D8%25A8-%25D9%2588%25D8%25A7%25D9%2584%25D8%25B5%25D8%25B1%25D9%2581-%25D8%25A7%25D9%2584%25D8%25B5%25D8%25AD%25D9%2589-%25D8%25A8%25D8%25A3%25D8%25B3%25D9%258A%25D9%2588%25D8%25B7-%25D9%2588%25D8%25A7%25D9%2584%25D9%2588%25D8%25A7%25D8%25AF%25D9%2589-%25D8%25A7%25D9%2584%25D8%25AC%25D8%25AF%25D9%258A%25D8%25AF-364679160333044%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=true&amp;adapt_container_width=false&amp;hide_cover=false&amp;show_facepile=true&amp;appId=887228017981898"
            style="border:none;overflow:hidden"
            scrolling="no"
            allowtransparency="true"
            width="100%"
            height="350"
            frameborder="0"
          ></iframe
        ></v-expansion-panel-content>
        <v-expansion-panel-content v-else
          ><h4 v-for="(subItemSite, i) in item.listofItem" :key="i">
            <a :href="subItemSite.link" class="importantSite">
              <v-icon>mdi-chevron-left</v-icon>
              {{ subItemSite.subImportantSite }}</a
            >
          </h4>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  data() {
    return {
      panels: [1],
      ArrayOFAnElsherka: [
        {
          icon: "mdi-arrow-right-drop-circle-outline",
          title: "رساله الشركة",
          img: require("@/assets/Images/7.png"),
        },
        {
          icon: "",
          title: "صفحتنا علي الفيسبوك",
          img:
            "http://aloarabia.com/wp-content/uploads/2015/11/Facebook-Logo-3D-Laptop-Wallpapers-660x3301.jpg",
          subText: "صفحتنا",
          link: "https://ar-ar.facebook.com/ASCWWeg/",
        },
        {
          icon: "",
          title: "مواقع هامة",
          listofItem: [
            {
              subImportantSite: "بوابه الحكومه المصريه",
              link: "https://www.egypt.gov.eg/arabic/home.aspx",
            },
            {
              subImportantSite: "رئاسه مجلس الوزراء",
              link: "https://www.cabinet.gov.eg/Arabic/Pages/default.aspx",
            },

            {
              subImportantSite: "الشركة القابضة لمياه الشرب و الصرف الصحى",
              link: "https://www.hcww.com.eg/ar",
            },
            {
              subImportantSite: "بوابه محافظه أسيوط",
              link: "http://assiut.gov.eg/",
            },
            {
              subImportantSite: " بوابة رئاسة الجمهوريه",
              link: "https://www.presidency.eg/ar",
            },
          ],
        },
      ],
    };
  },
  computed: {
    adminInfo() {
      return this.$store.state.adminInfo;
    },
  },
};
</script>
<style scoped>
.showImg {
  background: white;
  border: 1px;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
  font-family: inherit !important;
  font-weight: bold;
  color: gray !important;
}
.importantSite {
  text-decoration: none;
  color: rgba(0, 128, 0, 0.575);
}
</style>
